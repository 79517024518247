import React, { useEffect, useState } from "react";
import { random } from "lodash";

import "./FlashText.css";
import { randomIndexStringFromArray } from "../util";

const messages = [
  "sin",
  "consume",
  "america",
  "regret",
  "vote",
  "satan",
  "jesus",
  "oil",
  "",
  "",
  "reality",
  "",
  "hope",
  "love",
  "hate",
  "loss",
  "swim",
  "fear",
  "democracy",
  "USA",
  "subscribe",
  "issues",
  "",
  "",
  "",
];

export const FlashText: React.FC = () => {
  const [word, setWord] = useState<string>("");
  useEffect(() => {
    const text = setInterval(() => {
      setWord(messages[randomIndexStringFromArray(messages)]);
    }, 250);
    return () => clearInterval(text);
  }, []);

  return (
    <div className="soAboveSoBelow">
      <span>{word}</span>
    </div>
  );
};
