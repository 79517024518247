import React, { useEffect, useState } from "react";
import { randomIndexStringFromArray } from "../util";

import "./YouAreNothing.css";

const shittalk = [
  "you are nothing",
  "give in",
  "you are not enough",
  "you will never recover",
  "sleep forever",
  "/////////////////////////",
  "/.../.../......d./..s/.",
  "777",
  "666",
  "chaos",
  "tyranny",
  "========",
  "void",
  "sickness",
  "i am not a product of a loving god",
  "no one loves you",
  "...",
  "][.][.][.][....].].]..[.[.[[",
  "worth",
  "empty",
  "disappointment",
  "daddy's home",
];

const Component: React.FC = () => {
  const [fuck, setFuck] = useState<string | null>(null);
  useEffect(() => {
    setFuck(shittalk[randomIndexStringFromArray(shittalk)]);
    const el = document.querySelector("#fuckyou");
    setTimeout(() => {
      el?.classList.remove("hide");
      el?.classList.add("typing");
    }, 2200);
    setTimeout(() => {
      el?.classList.add("hide");
      el?.classList.remove("typing");
      setFuck(shittalk[randomIndexStringFromArray(shittalk)]);
      el?.classList.remove("hide");
      el?.classList.add("typing");
    }, 9600);
    setTimeout(() => {
      el?.classList.add("hide");
      el?.classList.remove("typing");
      setFuck(shittalk[randomIndexStringFromArray(shittalk)]);
      el?.classList.remove("hide");
      el?.classList.add("typing");
    }, 13600);
  }, []);
  return (
    <div className="you_are_nothing">
      <h5 id="fuckyou" className="hide">
        {fuck}
      </h5>
    </div>
  );
};

export const YouAreNothing = React.memo(Component);
