import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FlashText } from "./components/FlashText";
import { YouAreNothing } from "./components/YouAreNothing";
import axios from "axios";

const App: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);
  return (
    <div>
      <div>
        <h1>ｄｅａｄ．ｆｙｉ</h1>
        <h2>nthg clsr to deaxh - plz me, plz u</h2>
      </div>
      <div>
        <h3>phase 0</h3>
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          status:{" "}
          <span
            style={{
              display: "inline-block",
              width: 10,
              height: 10,
              borderRadius: "50%",
              backgroundColor: "green",
              margin: 2,
            }}
          ></span>
        </p>
        <h5>
          d&#1161;E&#1161;S&#1161;c&#1161;r&#1161;i&#1161;P&#1161;t&#1161;i&#1161;o&#1161;n&#1161;
        </h5>
        <p>preparation.</p>
      </div>
      <div>
        Tulit ergo Dominus Deus hominem et posuit eum in paradiso voluptatis, ut
        operaretur et custodiret illum;
      </div>
      <div>
        <h3>phase 1</h3>
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          status:{" "}
          <span
            style={{
              display: "inline-block",
              width: 10,
              height: 10,
              borderRadius: "50%",
              backgroundColor: "#aaa",
              margin: 2,
            }}
          ></span>
        </p>
        <h5>CxLLECTION</h5>
        <p style={{ marginBottom: 20 }}>
          sbmt every desire ++++./. thought ///////asifuwerealone
        </p>
        <p style={{ fontSize: 13 }}>repent‡‡‡‡ </p>
      </div>
      <div>
        <h3>phase 2</h3>
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          status:{" "}
          <span
            style={{
              display: "inline-block",
              width: 10,
              height: 10,
              borderRadius: "50%",
              backgroundColor: "#aaa",
              margin: 2,
            }}
          ></span>
        </p>
        <h5>consume|||regurgitate</h5>
        <p style={{ marginBottom: 20 }}>
          sbmt every desire ++++./. thought ///////asifuwerealone
        </p>
        <p style={{ fontSize: 13 }}>
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          parse............................ CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME
          CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME CONSUME{" "}
          CONSUME CONSUME CONSUME{" "}
        </p>
        <code>
          Then shall he say also unto them on the left hand, Depart from me, ye
          cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsThen shall he say also unto them on the left hand, Depart from
          me, ye cursed, into everlasting fire, prepared for the devil and his
          angelsv
        </code>
        <p>
          Even when we were dead in sins, hath quickened us together with
          Christ, (by grace ye are saved;)
        </p>
        <cite className="hail">
          It is a popular misconception that the Satanist does not believe in
          God. The concept of "God", as interpreted by man, has been so varied
          throughout the ages that the Satanist simply accepts the definition
          which suits him best. Man has always created his gods, rather than his
          gods creating him.
        </cite>
      </div>
      <h3 style={{ marginTop: 300 }}>will you join us?</h3>
      {!submitted && (
        <div
          style={{
            position: "relative",
            zIndex: 9,
          }}
        >
          <Formik
            initialValues={{ phone: "" }}
            validationSchema={Yup.object().shape({
              phone: Yup.string()
                .min(10)
                .max(10)
                .required("we need your phone number"),
            })}
            onSubmit={async (values, actions) => {
              await axios
                .post("https://deadfyiapi.onrender.com/sms/join", {
                  phone: values.phone,
                })
                .then((response) => {
                  setSubmitted(true);
                })
                .catch((err) => {
                  console.error(err);
                  setSubmitted(true);
                });
              actions.setSubmitting(false);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <label htmlFor="phone">
                  phone // 10digit +1 only (sms fees could apply if you have a
                  plan from 1999)
                </label>
                <Field
                  id="phone"
                  type="tel"
                  name="phone"
                  placeholder="1235551499"
                />
                {errors.phone && touched.phone ? (
                  <div style={{ color: "rebeccapurple" }}>{errors.phone}</div>
                ) : null}
                <button
                  type="submit"
                  style={{
                    border: "1px solid aqua",
                    backgroundColor: "aquamarine",
                  }}
                >
                  joinus
                </button>
              </Form>
            )}
          </Formik>
        </div>
      )}
      {submitted && (
        <div>
          <p>
            thank you. wait further instruction. your thoughts are important to
            the future. they are important to you.
          </p>
        </div>
      )}
      <FlashText />
      <YouAreNothing />
      <div style={{ marginTop: 400 }}>
        <p>
          no information will ever be sold. we do not participate in
          technological exploitation. this is an act against all we know. no
          personal information is shared, sold, used for reasons outside of this
          installation, nor do we care to track - monetize - or otherwise
          exploit the temple that is your identity. take care of yourself, move
          forward... grow - keep swimming. all thoughts parsed are not connected
          to inbound identification. ==== you are safe here. embrace us.
        </p>
      </div>
    </div>
  );
};

export default App;
